export class Order {
  ID: number = 0
  TID: string = 'S'
  OrderID: number = 1234
  CustomerID: number = 0
  StartIssueDate: string = ""
  Notes: string = ""
  Amount: number = 0
  SalesPersonID: number = 0
  Quantity: number = 0
  Commission: number = 0
  DiscountPercent: number = 0
  SRecAmount: number = 0
  SMailingListApproved: boolean = false
  SMailed: boolean = false
  SCTTested: boolean = false
  SRecdPayQB: boolean = false
  SComplete: boolean = false
  SPayRecd: boolean = false
  Inactive: boolean = false
  ServiceAmount: number = 0
  SendNewOrderEmail: boolean = false
}

export class OrderDetails {
  Order: Order
  Display: OrderDisplay
  Quantity: number
  Total: number
  Received: number
}

export class Services {
  ID: number
  CustID: number
  Name: string
  Title: string
  Phone: string
  Cell: string
  Fax: string
  Email: string
  Main: boolean
}

export class OrderDisplay {
  Customer: string
  Address: string
  City: string
  State: string
  Zipcode: string
  DBA: string
  Contact: string
  Phone: string
  Salesperson: string
  AcctMgr: string
}

export class ServiceMappingDisplay {
  ID: number
  OrderID: number
  Quantity: number
  ServiceFee: number
  ServiceID: number
  TID: number
  Service: string
}

export class Item {
  ID: number = 0
  TID: string = "S"
  OrderID: number = 0
  Status: number = 33
  Quantity: number = 0
  Rate: number = 0
  TNumber: string = ""
  Postmaster: string = ""
  ShipDate: string = ""
  PostcardSize: string = ""
  TemplateID: number = 0
  InHomeDate: string = ""
  PieceType: string = ""
  DesignerCost: number = 0
  ShipperCost: number = 0
  PrinterCost: number = 0
  USPSDate: string = ""
  Notes: string = ""
  RemoveFromDashboard: boolean = false
  LayoutNumber: number
  Postage: number
  TemplateFileID: number
  InvoiceDate: string
  ListCost: number
  TrackingNumber: string
  TargetNumber: string
  ListType: string
  NoPrintCost: boolean
  NumberOfCalls: string
  CreditCardFee: number
  ExtraCopiesFee: number
  ExtraCopiesQty: number
  MailerCost: number
  NumberOfCallsDate: string
  ReorderComplete: number
  Orientation: string
  ItemNumber: number
  NoMailCost: boolean
  NoListCost: boolean
  NoDesignCost: boolean
  OverrideUSPS: boolean
  OverrideShipDate: boolean
  Paid: boolean
  Commission: number
  ReadyToShip: string
  RemoveFromCallTracking: boolean
  ShipToMailer: string
  OverrideShipToMailer: boolean
  OverridePrintDate: boolean
  PrintDate: string
  PrinterID: number
  Seminar: boolean
    EventDate: string = ""
    IovoxID: string = ""
}

export class ItemDisplay {
  Item: Item
  Header: string
  Status: string
  Template: string
  MailingListLoaded: boolean
  Templates: any[]
  Sizes: any[]
  Payments: Payments[]
  PaymentTypes: PaymentType[]
  customerID: number = 0
  Printer: string
}

export class PaymentType {
  ID: number = 0
  PaymentType1: string = ""
}

export class Payments {
  ID: number = 0
  TID: string = "S"
  RecAmount: number = 0
  ReferenceNumber: string = null
  CheckNumber: string = null
  RecDate: string = null
  PaymentType: number = 0
  OrderID: number = 0
  CustID: number = 0
  PieceType: string = null
  PieceID: string = null
}

export class OrderCosts {
  Postage: number = 0
  PrinterCost: number = 0
  ShipperCost: number = 0
  MailerCost: number = 0
  ListCost: number = 0
  DesignerCost: number = 0
}


export class Appoints {

  ID: number
  Appointment: Date
  Salesperson: string
  Customer: string
  Reason: string
}
