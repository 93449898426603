

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class AccountingService {

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;

  getPaymentsRpt(m, y) { return this.http.get('/Accounting/getPayments', { params: new HttpParams().set("m", m).set("y", y) }); }

  getReceivables(m, y) { return this.http.get('/Accounting/GetReceivablesRpt', { params: new HttpParams().set("m", m).set("y", y) }); }

  getNewOrders(s, e) { return this.http.get('/Accounting/GetNewOrderRpt', { params: new HttpParams().set("start", s).set("end", e) }); }

  getSalesperson(s, e) { return this.http.get('/Accounting/GetSalespersonRpt', { params: new HttpParams().set("start", s).set("end", e) }); }

  getOrgRpt(s, e, o) { return this.http.get('/Accounting/GetOrgRpt', { params: new HttpParams().set("start", s).set("end", e).set("org", o) }); }

  getOrgRptRec(s, e, o) { return this.http.get('/Accounting/GetOrgRptRec', { params: new HttpParams().set("start", s).set("end", e).set("org", o) }); }

  getSalesRptInHome(y) { return this.http.get('/Accounting/GetSalesRptInHome', { params: new HttpParams().set("year", y) }); }

  getSalesRptRec(y) { return this.http.get('/Accounting/GetSalesRptRec', { params: new HttpParams().set("year", y) }); }

  getAcctRptInHome(s, e) { return this.http.get('/Accounting/GetAccountingRptIH', { params: new HttpParams().set("start", s).set("end", e) }); }

  getAcctRptRec(s, e) { return this.http.get('/Accounting/GetAccountingRptR', { params: new HttpParams().set("start", s).set("end", e) }); }

  getAcctRptShippedDate(s, e) { return this.http.get('/Accounting/GetAccountingRptS', { params: new HttpParams().set("start", s).set("end", e) }); }

  getPLInHome(m, y) { return this.http.get('/Accounting/GETPL1', { params: new HttpParams().set("m", m).set("y", y) }); }

  getPLRec(m, y) { return this.http.get('/Accounting/GETPL2', { params: new HttpParams().set("m", m).set("y", y) }); }

  getSalesDataRec(y) { return this.http.get('/Accounting/GetSalesDataRec', { params: new HttpParams().set("year", y) }); }

  getSalesDataInHome(y) { return this.http.get('/Accounting/GetSalesDataInHome', { params: new HttpParams().set("year", y) }); }

  getCommissionRpt(m, y) { return this.http.get('/Accounting/GetCommissions', { params: new HttpParams().set("m", m).set("y", y) }); }

  getCustRpt(sm,sy,em,ey,id) { return this.http.get('/Accounting/GetCustRpt', { params: new HttpParams().set("id", id).set("sm",sm).set("sy",sy).set("em",em).set("ey",ey) }); }
}
