import { Component, OnInit } from '@angular/core';
import { AppUserAuth } from '../security/app-user-auth';
import { SecurityService } from '../security/security.service';
import { MenuItem } from 'primeng/api';


import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent implements OnInit {
  securityObject: AppUserAuth = null;
  display = false;
  constructor(private http: HttpClient, private securityService: SecurityService) { this.securityObject = securityService.securityObject; }
  Items: MenuItem[];
  SuperAdminItems: MenuItem[];
  AdminItems: MenuItem[];
  DSOItems: MenuItem[];

  ngOnInit() {

    this.Items = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/dashboard'] },
      { label: 'Customers', icon: 'pi pi-fw pi-users', routerLink: ['/customers/list'] },
      { label: 'Orders', icon: 'pi pi-bars', routerLink: ['/orders/list'] },
      { label: 'Leads', icon: 'fa fa-user-plus', routerLink: ['/leads/list'] },
      { label: 'Notes', icon: 'fa fa-sticky-note', routerLink: ['/notes'] },
      { label: 'Calendar', icon: 'pi pi-calendar', routerLink: ['/calendar'] },
      { label: 'Reorders', icon: 'pi pi-folder-open', routerLink: ['/reorder'] },
      {
        label: 'Accounting', icon: 'pi pi-fw pi-home',
        items: [
          { label: 'Payments', icon: 'pi pi-fw pi-home', routerLink: ['/paymentsrpt'] },
          { label: 'Commissions', icon: '', routerLink: ['/commissions'] },
          { separator: true },
          { label: 'Customer Report', icon: 'pi pi-fw pi-user', routerLink: ['/custrpt'] },
          { label: 'Sales Report', icon: 'pi pi-fw pi-home', routerLink: ['/salesrpt'] },
          { label: 'Sales Data', icon: '', routerLink: ['/salesdata'] },
          { label: 'Accounting Report', icon: 'pi pi-fw pi-home', routerLink: ['/accountingrpt'] },
          { label: 'Salesperson Report', icon: 'pi pi-fw pi-home', routerLink: ['/salespersonrpt'] },
          { separator: true },
          { label: 'New Order Report', icon: 'pi pi-fw pi-home', routerLink: ['/neworderrpt'] }
        ]
      },
    ];

    this.DSOItems = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/dashboard/dso'] },
      { label: 'In Progress', icon: 'fas fa-running fa-sm', routerLink: ['/dashboard/dsoInProgress'] },
      { label: 'Locations', icon: 'fa fa-map-pin', routerLink: ['/customers/list'] },
      { label: 'Reports', icon: 'fas fa-list', routerLink: ['/dashboard/dsoReport'] }
    ];

    this.SuperAdminItems = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/dashboard'] },
      { label: 'Customers', icon: 'pi pi-fw pi-users', routerLink: ['/customers/list'] },
      { label: 'Orders', icon: 'pi pi-bars', routerLink: ['/orders/list'] },
      { label: 'Leads', icon: 'fa fa-user-plus', routerLink: ['/leads/list'] },
      { label: 'Notes', icon: 'fa fa-sticky-note', routerLink: ['/notes'] },
      { label: 'Calendar', icon: 'pi pi-calendar', routerLink: ['/calendar'] },
      { label: 'Reorders', icon: 'pi pi-folder-open', routerLink: ['/reorder'] },
      {
        label: 'Accounting', icon: 'pi pi-dollar',
        items: [
          { label: 'Payments', icon: '', routerLink: ['/paymentsrpt'] },
          { label: 'Commissions', icon: '', routerLink: ['/commissions'] },
          //   { label: 'Receivables', icon: '', routerLink: ['/receivablessrpt'] },
          { separator: true },
          { label: 'Organizational Report', icon: '', routerLink: ['/organizationrpt'] },
          { separator: true },
          { label: 'Customer Report', icon: '', routerLink: ['/custrpt'] },
          { label: 'Sales Report', icon: '', routerLink: ['/salesrpt'] },
          { label: 'Sales Data', icon: '', routerLink: ['/salesdata'] },
          { label: 'Accounting Report', icon: '', routerLink: ['/accountingrpt'] },
          { label: 'Salesperson Report', icon: '', routerLink: ['/salespersonrpt'] },
          { separator: true },
          { label: 'Profit & Loss', icon: '', routerLink: ['/profitloss'] },
          { label: 'New Order Report', icon: '', routerLink: ['/neworderrpt'] }
        ]
      },
      {
        label: 'Misc', icon: 'pi pi-fw pi-cog',
        items: [
          { label: 'Upload QCKenetix file', icon: '', routerLink: ['/maintenance/qckenetix'] },
          { label: 'Black Out Dates', icon: '', routerLink: ['/maintenance/blackoutdates'] },
          { label: 'Industries', icon: '', routerLink: ['/maintenance/industries'] },
          { label: 'Lead Sources', icon: '', routerLink: ['/maintenance/leadsources'] },
          { label: 'Sales People', icon: '', routerLink: ['/maintenance/salespeople'] },
          { label: 'Services', icon: '', routerLink: ['/maintenance/services'] },
          { label: 'Sizes', icon: '', routerLink: ['/maintenance/postcardsizes'] },
          { label: 'Statuses', icon: '', routerLink: ['/maintenance/statuses'] },
          { label: 'Templates', icon: '', routerLink: ['/maintenance/templates'] },
          { separator: true },
          { label: 'Multiple Locations', icon: '', routerLink: ['/maintenance/grouping'] },
          { label: 'Organizations', icon: '', routerLink: ['/maintenance/orgs'] },
          { label: 'Service Centers', icon: '', routerLink: ['/maintenance/servicecenters'] },
          { label: 'Printers', routerLink: ['/maintenance/printers'] },
          { separator: true },
          { label: 'Company', icon: '', routerLink: ['/maintenance/company'] },
          { label: 'Users', icon: '', routerLink: ['/maintenance/users'] },
          { label: 'QC Kinetic Users', icon: '', routerLink: ['/maintenance/qcUsers'] }
        ]
      }
    ];

    this.AdminItems = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/dashboard'] },
      { label: 'Customers', icon: 'pi pi-fw pi-users', routerLink: ['/customers/list'] },
      { label: 'Orders', icon: 'pi pi-bars', routerLink: ['/orders/list'] },
      { label: 'Leads', icon: 'fa fa-user-plus', routerLink: ['/leads/list'] },
      { label: 'Notes', icon: 'fa fa-sticky-note', routerLink: ['/notes'] },
      { label: 'Calendar', icon: 'pi pi-calendar', routerLink: ['/calendar'] },
      { label: 'Reorders', icon: 'pi pi-folder-open', routerLink: ['/reorder'] },
      {
        label: 'Accounting', icon: 'pi pi-fw pi-home',
        items: [
          { label: 'Payments', icon: 'pi pi-fw pi-home', routerLink: ['/paymentsrpt'] },
          //  { label: 'Receivables', icon: 'pi pi-fw pi-home', routerLink: ['/receivablessrpt'] },
          { label: 'Commissions', icon: '', routerLink: ['/commissions'] },
          { separator: true },
          { label: 'Organizationial Report', icon: 'pi pi-fw pi-home', routerLink: ['/organizationrpt'] },
          { separator: true },
          { label: 'Customer Report', icon: 'pi pi-fw pi-user', routerLink: ['/custrpt'] },
          { label: 'Sales Report', icon: 'pi pi-fw pi-home', routerLink: ['/salesrpt'] },
          { label: 'Sales Data', icon: '', routerLink: ['/salesdata'] },
          { label: 'Accounting Report', icon: 'pi pi-fw pi-home', routerLink: ['/accountingrpt'] },
          { label: 'Salesperson Report', icon: 'pi pi-fw pi-home', routerLink: ['/salespersonrpt'] },
          { separator: true },
          { label: 'Profit & Loss', icon: 'pi pi-fw pi-home', routerLink: ['/profitloss'] },
          { label: 'New Order Report', icon: 'pi pi-fw pi-home', routerLink: ['/neworderrpt'] }
        ]
      },
      {
        label: 'Misc', icon: 'pi pi-fw pi-home',
        items: [
          { label: 'Black Out Dates', routerLink: ['/maintenance/blackoutdates'] },
          { label: 'Industries', routerLink: ['/maintenance/industries'] },
          { label: 'Lead Sources', routerLink: ['/maintenance/leadsources'] },
          { label: 'Sales People', icon: '', routerLink: ['/maintenance/salespeople'] },
          { label: 'Services', routerLink: ['/maintenance/services'] },
          { label: 'Sizes', routerLink: ['/maintenance/postcardsizes'] },
          { label: 'Statuses', routerLink: ['/maintenance/statuses'] },
          { label: 'Templates', routerLink: ['/maintenance/templates'] },
          { separator: true },
          { label: 'Multiple Locations', routerLink: ['/maintenance/grouping'] },
          { label: 'Organizations', routerLink: ['/maintenance/orgs'] },
          { label: 'Service Centers', routerLink: ['/maintenance/servicecenters'] },
          { label: 'Printers', routerLink: ['/maintenance/printers'] }
        ]
      }
    ];

  }

  logout(): void { this.securityService.logout(); }
}
