import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './security/auth.guard';
import { HttpInterceptorModule } from './security/http-interceptor.module';

import { HasClaimDirective } from './security/has-claim.directive';
import { CompareValidator } from './Directives/CompareValidator';
import { SharedModule } from './Shared/SharedModule';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MenubarModule } from 'primeng/menubar';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { DragDropModule } from 'primeng/dragdrop';
//import { GMapModule } from 'primeng/gmap';
import { CalendarModule } from 'primeng/calendar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeTableModule } from 'primeng/treetable';
import { ListboxModule } from 'primeng/listbox';
import { LoginComponent } from './security/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectManagerComponent } from './dashboard/projectManager/projectManager.component';
import { DesignerComponent } from './dashboard/designer/designer.component';
import { PrinterComponent } from './dashboard/printer/printer.component';
import { MailerComponent } from './dashboard/mailer/mailer.component';
import { ShipperComponent } from './dashboard/shipper/shipper.component';
import { PendingComponent } from './dashboard/pending/pending.component';
import { CallTrackingComponent } from './dashboard/callTracking/call-tracking.component';
import { RecentComponent } from './dashboard/recent/recent.component';
//import { MapComponent } from './dashboard/map/map.component';
import { NotesComponent } from './notes/notes.component';
import { ReorderComponent } from './reorder/reorder.component';
import { PaymentsComponent } from './accounting/payments/payments.component';
import { ReceivablesComponent } from './accounting/receivables/receivables.component';
import { NewOrderComponent } from './accounting/new-order/new-order.component';
import { SalespersonReportComponent } from './accounting/salesperson-report/salesperson-report.component';
import { OrganizationReportComponent } from './accounting/organization-report/organization-report.component';
import { SalesReportComponent } from './accounting/sales-report/sales-report.component';
import { AccountingReportComponent } from './accounting/accounting-report/accounting-report.component';
import { ProfitLossComponent } from './accounting/profit-loss/profit-loss.component';
import { SearchComponent } from './dashboard/search/search.component';
import { SalesDataComponent } from './accounting/sales-data/sales-data.component';
//import { GatherCallTrackingComponent } from './gathercall-tracking/gathercall-tracking.component';
import { CalendarComponent } from './calendar/calendar.component';
import { DsoComponent } from './dashboard/dso/dso.component';
import { DsoInProgressComponent } from './dashboard/dsoinprogress/dsoinprogress.component';
import { CommissionsComponent } from './accounting/commission/commission.component';
import { DsoReportComponent } from './dashboard/dso-report/dso-report.component';
import { CustomerReportComponent } from './accounting/customer-report/customer-report.component';

//import { CampaignsComponent } from './campaigns/campaigns.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    HasClaimDirective,
    DashboardComponent,
    ProjectManagerComponent,
    DesignerComponent,
    PrinterComponent,
    MailerComponent,
    ShipperComponent,
    PendingComponent,
    CallTrackingComponent,
   // GatherCallTrackingComponent,
    RecentComponent,
    //MapComponent,
    CompareValidator,
    NotesComponent,
    ReorderComponent,
    PaymentsComponent,
    ReceivablesComponent,
    NewOrderComponent,
    SalespersonReportComponent,
    OrganizationReportComponent,
    SalesReportComponent,
    AccountingReportComponent,
    ProfitLossComponent,
    SearchComponent,
    SalesDataComponent,
    CalendarComponent,
    DsoComponent,
    DsoInProgressComponent,
    CommissionsComponent,
    DsoReportComponent,
    CustomerReportComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    TooltipModule,
    MenubarModule,
    DialogModule,
    NgbModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    HttpInterceptorModule,
    PanelModule,
    AccordionModule,
    InputTextModule,
    InputSwitchModule,
    DropdownModule,
    InputTextareaModule,
    MultiSelectModule,
    ButtonModule,
    InputTextModule,
    DragDropModule,
  //  GMapModule,
    CalendarModule,
    TriStateCheckboxModule,
    FileUploadModule,
    SharedModule,
    TreeTableModule,
    ListboxModule, 

    RouterModule.forRoot([
      { path: '', component: LoginComponent },
      {
        path: 'dashboard', component: DashboardComponent,
        children: [
          { path: 'projectmanager', component: ProjectManagerComponent },
          { path: 'designer', component: DesignerComponent },
          { path: 'printer', component: PrinterComponent },
          { path: 'dso', component: DsoComponent },
          { path: 'dsoInProgress', component: DsoInProgressComponent },
          { path: 'dsoReport', component: DsoReportComponent },
          { path: 'mailer', component: MailerComponent },
          { path: 'shipper', component: ShipperComponent },
          { path: 'pending', component: PendingComponent },
          { path: 'calltracking', component: CallTrackingComponent },
          { path: 'recent', component: RecentComponent },
          { path: 'search', component: SearchComponent }
        ]
      },
      { path: 'customers', loadChildren: './customers/customer.module#CustomerModule' },
      { path: 'leads', loadChildren: './leads/lead.module#LeadsModule' },
      { path: 'orders', loadChildren: './orders/orders.module#OrdersModule' },
      { path: 'notes', component: NotesComponent },
      { path: 'calendar', component: CalendarComponent },
      { path: 'reorder', component: ReorderComponent },
      { path: 'maintenance', loadChildren: "./Maintenance/maintenance.module#MaintenanceModule" },
      { path: 'paymentsrpt', component: PaymentsComponent },
      { path: 'commissions', component: CommissionsComponent },
      { path: 'receivablessrpt', component: ReceivablesComponent },
      { path: 'neworderrpt', component: NewOrderComponent },
      { path: 'salespersonrpt', component: SalespersonReportComponent },
      { path: 'organizationrpt', component: OrganizationReportComponent },
      { path: 'custrpt', component: CustomerReportComponent },
      { path: 'salesrpt', component: SalesReportComponent },
      { path: 'salesdata', component: SalesDataComponent },
      { path: 'accountingrpt', component: AccountingReportComponent },
      { path: 'profitloss', component: ProfitLossComponent },
      { path: 'login', component: LoginComponent },
  //    { path: 'gatherCT', component: GatherCallTrackingComponent } 
      //{ path: 'campaigns', component: CampaignsComponent }
      
    ]),
  ],
  exports: [TableModule],
  providers: [AuthGuard],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
